import React from "react";
import './AboutUs.css';

const AboutUs: React.FC = () => {
    return (
        <>
        <div className="about-us-wrap" style={{backgroundImage: 'url("img/about/map.svg")'}}>
            <span className="about-us-title-1">ULA — United Logistics Asia.</span>
            <span className="about-us-title-2">Доставка и оплата грузов <br/> из Китая, Южной Кореи<br/> и других стран Азии</span>
            <span className="about-us-desc">Мы предлагаем комплексные решения: от оплаты накладных <br/> поставщику до быстрой и надежной доставки грузов к вашей<br/> двери или складу с профессиональной консультацией и<br></br> полным оформлением документов.</span>
            <div className="about-us-container">
                <div className="about-us-params-1">
                    <p>1024</p>
                    <span>Палетомест на складах</span>
                </div>
                <div className="about-us-params-2">
                    <p>19</p>
                    <span>Складов в России и Азии</span>
                </div>
            </div>
            <span className="about-us-share-btn">Найти офисы и склады<img src="img/about/vector.svg"/></span>
        </div>
        
        <span className="main-title-type-1" style={{marginTop: '60%'}}>Наши ключевые особенности</span>
        <div className="about-us-flx-container">
            <div className="about-us-flx-item">
                <img src="img\about\item-1.svg" alt="" />
                <span className="about-us-flx-item-title">Гарантия для наших партнеров</span>
                <span className="about-us-flx-item-desc">Мы оформляем договор с юридическими и<br></br> физическими лицами, в которых четко указаны <br></br>условия сотрудничества, сроки доставки и тарифы<br></br> на транспортировку.</span>
            </div>
            <div className="about-us-flx-item">
                <img src="img\about\item-2.svg" alt="" />
                <span className="about-us-flx-item-title">Удобный способ оплаты счетов<br></br> поставщикам в Китай</span>
                <span className="about-us-flx-item-desc">Мы помогаем в оплате счетов поставщикам в CNY и <br></br>USD на счет компании и AliPay/Wechat с ИП и ООО. </span>
            </div>
            <div className="about-us-flx-item">
                <img src="img\about\item-3.svg" alt="" />
                <span className="about-us-flx-item-title">Оплата наших услуг только после<br></br> получения груза </span>
                <span className="about-us-flx-item-desc">Вы оплачиваете доставку до вашего дома <br></br>или склада по факту получения вами груза.</span>
            </div>
            <div className="about-us-flx-item">
                <img src="img\about\item-4.svg" alt="" />
                <span className="about-us-flx-item-title">Личная встреча</span>
                <span className="about-us-flx-item-desc">Вы всегда можете приехать в наш офис в Москве и подписать договор лично, будем рады знакомству с вами.</span>
            </div>
            <div className="about-us-flx-item">
                <img src="img\about\item-5.svg" alt="" />
                <span className="about-us-flx-item-title">Полная защита ваших грузов</span>
                <span className="about-us-flx-item-desc">Каждый груз под нашей ответственностью имеет полное страховое покрытие: от возможной частичной или полной утери, а также от задержек сроком до 180 дней.</span>
            </div>
            <div className="about-us-flx-item">
                <img src="img\about\item-6.svg" alt="" />
                <span className="about-us-flx-item-title">Онлайн-контроль за перемещением груза</span>
                <span className="about-us-flx-item-desc">В личном кабинете у вас есть возможность в любое время отслеживать местоположение вашего груза на каждом этапе его доставки</span>
            </div>
        </div>
        </>
    )
}

export default AboutUs;