import React from "react";
import "./FAQ.css";

const FAQ: React.FC = () => {
    function toggleAnswer(element: HTMLElement): void {
        const answer = element.parentElement?.nextElementSibling as HTMLElement;
        if (answer) {
            answer.style.display = answer.style.display === 'block' ? 'none' : 'block';
        }
    }

    return (
        <>
            <div className="container">
                <div className="faq">
                    <h2>Популярные вопросы <br />и ответы</h2>
                    <div className="question">
                        <div className="question-text">Доставляете ли вы карго?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Срок доставки составляет от 3 до 7 рабочих дней.</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Как я могу быть уверен, что получу свой товар?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Вы можете вернуть товар в течение 14 дней с момента покупки.</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Что делать, если придет брак?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Вы можете вернуть товар в течение 14 дней с момента покупки.</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Что делать, если придет брак?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Что делать если моему товару нужна особая упаковка?</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Что делать, если придет брак?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Сколько занимает доставка?</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Что делать, если вы привезли товар не в срок?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Сколько занимает доставка?</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Какие товары вы возите?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Сколько занимает доставка?</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Как узнать, что поставщик надежный?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Сколько занимает доставка?</div>
                    <hr />
                </div>

                <div className="feedback">
                    <h2>Перезвоните мне</h2>
                    <span>Мы свяжемся с вами в течении 10 минут для <br></br>уточнения всех деталей.</span>
                    <form action="/" method="post" id="bitrix_form">
                        <input id="client_name" name="client_name" placeholder="Ваше Имя"required />
                        <hr></hr>

                        <input id="client_phone" placeholder="+7 (999) 999-00-00" name="client_phone" required />
                        <hr></hr>

                        <input id="client_email" placeholder="abc@mail.ru" name="client_email" required />
                        <hr></hr>

                        <textarea id="comment" name="comment" placeholder="Комментарий" rows={4} required></textarea>
                        <hr></hr>
                        <button type="submit">Перезвоните мне <img src="\img\Union.svg" alt="" /></button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default FAQ;
