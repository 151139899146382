import AboutUs from "./components/calculator/AbotUs/AboutUs.tsx";
import CalcLay from "./components/calculator/CalcLay/CalcLay.tsx";
import Calculator from "./components/calculator/Calculator.tsx";
import FAQ from "./components/calculator/FAQ/FAQ.tsx";
import Indastrias from "./components/calculator/Industrias/Indastrias.tsx";
import NavBar from "./components/calculator/NavBar/NavBar.tsx";
function App() {
  return (
    <div className="App">
      <CalcLay/>
      <AboutUs/>
      <Indastrias/>
      <FAQ/>
    </div>
  );
}

export default App;
