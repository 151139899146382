import React from "react";
import "./CalcLay.css";
import NavBar from "../NavBar/NavBar.tsx";
import Calculator from "../Calculator.tsx";

const CalcLay: React.FC = () => {
    return (
        <div className="calc-lay" style={{ backgroundImage: 'url("img/CalcLay/vibrant-red-truck-with-container-against-industrial-background 1.svg")' }}>
            <NavBar/>
            <span className="calc-lay-title">Доставка и выкуп товаров <br></br>из Китая, Южной Кореи и <br></br>других стран Азии</span>
            <span id="services">Услуги</span>
            <span id="consultation">Консультация</span>
            <span className="calc-lay-desc">Поможем, как малому бизнесу, так и крупному. Работаем с партиями<br></br> любых размеров, подбираем выгодный маршрут и оформляем<br></br> необходимые документы. Если вы знаете все тонкости <br></br> своей поставки, то для полного расчета стоимости <br></br> можете воспользоваться нашим калькулятором</span>
            <div className="calc-panel">
                <h2>Перезвоните мне</h2>
                    <span>Мы свяжемся с вами в течении 10 минут для <br></br>уточнения всех деталей.</span>
                    <form action="/" method="post" id="bitrix_form">
                        <input id="client_name" name="client_name" placeholder="Ваше Имя"required />
                        <hr></hr>

                        <input id="client_phone" placeholder="+7 (999) 999-00-00" name="client_phone" required />
                        <hr></hr>

                        <input id="client_email" placeholder="abc@mail.ru" name="client_email" required />
                        <hr></hr>

                        <textarea id="comment" name="comment" placeholder="Комментарий" rows={4} required></textarea>
                        <hr></hr>
                        <button type="submit">Перезвоните мне <img src="\img\Union.svg" alt="" /></button>
                    </form>
            </div>
        </div>
    );
}

export default CalcLay;
