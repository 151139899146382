import React from "react";
import "./Indasrtrias.css"

const Indastrias: React.FC = () => {
    return (
        <div className="indastrias-wrap">
            <span className="ind-title">
                Товары от лучших поставщиков Китая и Кореи: <br/>
                мы выкупим и доставим в Россию.
            </span>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '2%', flexDirection: 'row', width: '95%'}}>
                <img className="ind-logo" src="img/industrias/Vector.svg"/>
                <span className="ind-desc">
                    Выберите поставщика и оформите покупку самостоятельно<br></br>
                    - мы позаботимся о достаавке. Или передайте нам контакты<br></br>
                    поставщика, и мы возьмем на себя переговоры, заключение контракта <br></br>
                    и оплату, представляя наши деловые интересы.
                </span>
            </div>
            <div className="ind-flx-item-container">
                <div className="ind-flx-item">
                    <div className="ind-flx-item-text-container">
                        <span className="ind-flx-item-title">Товары для детей</span>
                        <span className="ind-flx-item-desc">
                            В китае представлено множество брендов<br></br>
                            детских товаров - от известных мировых <br></br>
                            до перспективных локальных <br></br>
                            производителей. Мы организуем доставку<br></br>
                            качественных детских товаров как для <br></br>
                            бизнеса, так и для личного пользования,<br></br>
                            обеспечивая выгодные цены и надежный сервис.<br></br>
                        </span>
                        <div className="ind-flx-item-btn-wrap">
                            <span>Перейти</span>
                            <img src="img\industrias\Union.svg"/>
                        </div>
                    </div>
                    <img className="ind-flx-item-img" src="img\industrias\surprise-box 1.svg" style={{width: '100%'}}/>
                </div>



                <div className="ind-flx-item">
                    <div className="ind-flx-item-text-container">
                        <span className="ind-flx-item-title">Одежда и обувь</span>
                        <span className="ind-flx-item-desc">
                        Мы поможем вам найти и доставить любую<br></br>
                        одежду и обувь из Китая для перепродажи <br></br>
                        в России. Работаем с надежными <br></br>
                        поставщиками, обеспечивая широкий<br></br>
                        выбор и привлекательные условия <br></br>
                        для бизнеса. 
                        </span>
                        <div className="ind-flx-item-btn-wrap">
                            <span>Перейти</span>
                            <img src="img\industrias\Union.svg"/>
                        </div>
                    </div>
                    <img className="ind-flx-item-img" src="img\industrias\surprise-box 5.svg" style={{width: '100%'}}/>
                </div>



                <div className="ind-flx-item">
                    <div className="ind-flx-item-text-container">
                        <span className="ind-flx-item-title">Промышленное<br></br> оборудование </span>
                        <span className="ind-flx-item-desc">
                        Доставка сложного и габаритного <br></br>
                        оборудования требует специального <br></br>
                        подхода и опыта, и мы с гордостью <br></br>
                        предоставляем этот сервис. Примеры <br></br>
                        грузов: Фрезерные станки, бурильные установки.
                        </span>
                        <div className="ind-flx-item-btn-wrap">
                            <span>Перейти</span>
                            <img src="img\industrias\Union.svg"/>
                        </div>
                    </div>
                    <img className="ind-flx-item-img" src="img\industrias\surprise-box 2.svg" style={{width: '100%'}}/>
                </div>



                <div className="ind-flx-item">
                    <div className="ind-flx-item-text-container">
                        <span className="ind-flx-item-title">Техника и <br></br>электроника</span>
                        <span className="ind-flx-item-desc">
                        Обеспечим бережную транспортировку <br></br>
                        электроники, чтобы поддержать ваши <br></br>
                        продажи и удовлетворить потребности <br></br>
                        клиентов в современном оборудовании.
                        </span>
                        <div className="ind-flx-item-btn-wrap">
                            <span>Перейти</span>
                            <img src="img\industrias\Union.svg"/>
                        </div>
                    </div>
                    <img className="ind-flx-item-img" src="img\industrias\surprise-box 6.svg" style={{width: '100%'}}/>
                </div>




                <div className="ind-flx-item">
                    <div className="ind-flx-item-text-container">
                        <span className="ind-flx-item-title">Спорт и отдых</span>
                        <span className="ind-flx-item-desc">
                        Доставим необходимые товары и <br></br>
                        оборудование для спорта и отдыха, от <br></br>
                        спортивной экипировки до снаряжения для <br></br>
                        кемпинга — мы позаботимся о том, чтобы <br></br>
                        все изделия прибыли вовремя и в идеальном состоянии.
                        </span>
                        <div className="ind-flx-item-btn-wrap">
                            <span>Перейти</span>
                            <img src="img\industrias\Union.svg"/>
                        </div>
                    </div>
                    <img className="ind-flx-item-img" src="img\industrias\surprise-box 3.svg" style={{width: '100%'}}/>
                </div>




                <div className="ind-flx-item">
                    <div className="ind-flx-item-text-container">
                        <span className="ind-flx-item-title">Маркетплейсы - <br></br>Китай </span>
                        <span className="ind-flx-item-desc">
                        Доставка любых товаров с маркетплейсов <br></br>
                        Китая. Поможем выбрать надежного <br></br>
                        продавца и оплатить поставку с Taobao, <br></br>
                        1688, Poizon, Pinduoduo, Vipshop
                        </span>
                        <div className="ind-flx-item-btn-wrap">
                            <span>Перейти</span>
                            <img src="img\industrias\Union.svg"/>
                        </div>
                    </div>
                    <img className="ind-flx-item-img" src="img\industrias\surprise-box 7.svg" style={{width: '100%'}}/>
                </div>



                <div className="ind-flx-item">
                    <div className="ind-flx-item-text-container">
                        <span className="ind-flx-item-title">Маркетплейсы - <br></br>Южная Корея</span>
                        <span className="ind-flx-item-desc">
                        Доставка любых товаров с маркетплейсов <br></br>
                        Южной Кореи. Поможем выбрать надежного <br></br>
                        продавца и оплатить поставку.
                        </span>
                        <div className="ind-flx-item-btn-wrap">
                            <span>Перейти</span>
                            <img src="img\industrias\Union.svg"/>
                        </div>
                    </div>
                    <img className="ind-flx-item-img" src="img\industrias\surprise-box 4.svg" style={{width: '100%'}}/>
                </div>

                <div className="gift-item" style={{backgroundImage: "url('img/industrias/red-gift-box-with-ribbon-bow-vector-illustration 1.svg')"}}>
                <div className="ind-flx-item-text-container">
                        <span className="ind-flx-item-title" style={{color: 'white'}}>
                            Специально для вас <br></br>
                            мы приготовили <br></br>
                            подарок
                        </span>
                        <div className="ind-flx-item-gift-wrap" style={{backgroundColor: 'white', border: 'none'}}>
                            <span>Получить подарок</span>
                            <img src="img\industrias\Arrow_right_light.svg"/>
                        </div>
                    </div>
                    <span className="ind-flx-item-desc" style={{color: 'white', width: '80%'}}>
                    Перейдите в наш Telegram бот, <br></br>чтобы узнать подробней
                        </span>
                </div>
            </div>
        </div>
    );
}

export default Indastrias;
