import React from "react";
import "./NavBar.css";

const NavBar: React.FC = () => {
    return (
        <div className="navbar">
            <ul className="nav-list">
                <img src="/img/navBar/logo.svg" alt="Description of image" />
                <li>О нас</li>
                <li>Услуги</li>
                <li>Калькулятор</li>
                <li>
                    <ul className="sub-list">
                        <li>Доп. информация</li>
                        {/* Добавьте дополнительные пункты, если нужно */}
                    </ul>
                </li>
            </ul>
            <div className="nav-contacts">
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <img src="/img/NavBar/circle.svg" alt="" />
                    <span style={{textWrap: 'nowrap'}}>+7 (000) 000-00-00</span>
                </div>
                
                <span id="recall">Перезвонить</span>
            </div>
        </div>

    );
}

export default NavBar;
